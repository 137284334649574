export const dates = [
  {
    startDate: '2023-01-01T00:00:00.000Z',
    endDate: '2023-12-31T23:59:59.999Z',
  },
  {
    startDate: '2023-01-01T00:00:00.000Z',
    endDate: '2023-03-31T23:59:59.999Z',
  },
  {
    startDate: '2023-04-01T00:00:00.000Z',
    endDate: '2023-06-30T23:59:59.999Z',
  },
  {
    startDate: '2023-07-01T00:00:00.000Z',
    endDate: '2023-09-30T23:59:59.999Z',
  },
  {
    startDate: '2023-10-01T00:00:00.000Z',
    endDate: '2023-12-31T23:59:59.999Z',
  },
  {
    startDate: '2024-01-01T00:00:00.000Z',
    endDate: '2024-12-31T23:59:59.999Z',
  },
  {
    startDate: '2024-01-01T00:00:00.000Z',
    endDate: '2024-03-31T23:59:59.999Z',
  },
  {
    startDate: '2024-04-01T00:00:00.000Z',
    endDate: '2024-06-30T23:59:59.999Z',
  },
  {
    startDate: '2024-07-01T00:00:00.000Z',
    endDate: '2024-09-30T23:59:59.999Z',
  },
  {
    startDate: '2024-10-01T00:00:00.000Z',
    endDate: '2024-12-31T23:59:59.999Z',
  },
  {
    startDate: '2023-01-01T00:00:00.000Z',
    endDate: '2023-06-30T23:59:59.999Z',
  },
  {
    startDate: '2023-07-01T00:00:00.000Z',
    endDate: '2023-12-31T23:59:59.999Z',
  },
];

export const quartersWithData: any = {
  2023: {
    Q1: false,
    Q2: false,
    Q3: true,
    Q4: true,
  },
  2024: {
    Q1: true,
    Q2: false,
    Q3: false,
    Q4: false,
  },
};

export const outComeDropdownValues = [
  {
    value: 'Deal Size',
    label: 'Deal Size (Amount)',
  },
  {
    value: 'Overall Bookings',
    label: 'Overall Bookings (Amount)',
  },
  {
    value: 'Pipe to Close Ratio', 
    label: 'Pipe to Close Ratio',
  },
  {
    value: 'Sales Cycle',
    label: 'Sales Cycle (Days)',
  },
  {
    value: 'Win Rate',
    label: 'Win Rate %',
  },
];

export const roleName: { [key: string]: string } = {
  ADMIN: 'Admin',
  LEADER: 'Manager',
  AE: 'Rep',
  EXEC: 'Exec',
};

export const personaList = [
  {
    value: 'ADMIN',
    name: 'Admin',
  },
  {
    value: 'LEADER',
    name: 'Manager',
  },
  {
    value: 'AE',
    name: 'Rep',
  },
  {
    value: 'EXEC',
    name: 'Exec',
  },
  {
    value: 'SDR',
    name: 'SDR',
  },
];

export const fiveBubbleLabel: { [key: number]: string } = {
  '0':
    '<div><span style="display:flex;justify-content:center">Bottom</span><span style="display:flex;justify-content:center;">10%</span></div>',
  '1':
    '<div><span style="display:flex;justify-content:center">Bottom</span><span style="display:flex;justify-content:center;">Quartile</span></div>',
  '2':
    '<div><span style="display:flex;justify-content:center">Middle</span><span style="display:flex;justify-content:center;">Performer</span></div>',
  '3':
    '<div><span style="display:flex;justify-content:center">Top</span><span style="display:flex;justify-content:center;">Quartile</span></div>',
  '4':
    '<div><span style="display:flex;justify-content:center">Top</span><span style="display:flex;justify-content:center;">10%</span></div>',
};

export const threeBubbleLabel: { [key: number]: string } = {
  '0':
    '<div><span style="display:flex;justify-content:center">Bottom</span><span style="display:flex;justify-content:center;">10%</span></div>',
  '1':
    '<div><span style="display:flex;justify-content:center">Middle</span><span style="display:flex;justify-content:center;">Performer</span></div>',
  '2':
    '<div><span style="display:flex;justify-content:center">Top</span><span style="display:flex;justify-content:center;">10%</span></div>',
};

export const fourBubbleLabel: { [key: number]: string } = {
  '0':
    '<div><span style="display:flex;justify-content:center">Bottom</span><span style="display:flex;justify-content:center;">10%</span></div>',
  '1':
    '<div><span style="display:flex;justify-content:center">Bottom</span><span style="display:flex;justify-content:center;">Quartile</span></div>',
  '2':
    '<div><span style="display:flex;justify-content:center">Top</span><span style="display:flex;justify-content:center;">Quartile</span></div>',
  '3':
    '<div><span style="display:flex;justify-content:center">Top</span><span style="display:flex;justify-content:center;">10%</span></div>',
};

export const fiveBubbleHeaders: { [key: number]: string } = {
  '1': 'Low-Performers',
  '2': 'Below Average - Performers',
  '3': 'Mid-Performers',
  '4': 'High-Performers',
  '5': 'Out-Performers',
};

export const fourBubbleHeaders: { [key: number]: string } = {
  '1': 'Low-Performers',
  '2': 'Mid-Performers',
  '3': 'High-Performers',
  '4': 'Out-Performers',
};
export const threeBubbleHeaders: { [key: number]: string } = {
  '1': 'Low-Performers',
  '2': 'Mid-Performers',
  '3': 'High-Performers',
};

export const percentageDrivers = [
  'Call To Email Ratio',
  'Meeting To Email Ratio',
  'Opportunity Stage Conversion',
];

export const colorCode = [
  { primary: 'rgb(224, 69, 50)', secondary: 'rgb(224  69  50 / 20%)' },
  { primary: 'rgb(242, 113, 69)', secondary: 'rgb(242  113  69 / 20%)' },
  { primary: 'rgb(245, 126, 75)', secondary: 'rgb(245  126  75 / 20%)' },
  { primary: 'rgb(251, 162, 93)', secondary: 'rgb(251  162  93 / 20%)' },
  { primary: 'rgb(166, 217, 111)', secondary: 'rgb(166  217  111 / 20%)' },
  { primary: 'rgb(163, 216, 110)', secondary: 'rgb(163  216  110 / 20%)' },
  { primary: 'rgb(154, 212, 107)', secondary: 'rgb(154  212  107 / 20%)' },
  { primary: 'rgb(156, 213, 108)', secondary: 'rgb(156  213  108 / 20%)' },
  { primary: 'rgb(136, 204, 103)', secondary: 'rgb(136  204  103 / 20%)' },
  { primary: 'rgb(116, 195, 100)', secondary: 'rgb(116  195  100 / 20%)' },
  { primary: 'rgb(128, 200, 102)', secondary: 'rgb(128  200  102 / 20%)' },
  { primary: 'rgb(124, 199, 101)', secondary: 'rgb(124  199  101 / 20%)' },
  { primary: 'rgb(251, 160, 92)', secondary: 'rgb(251  160  92 / 20%)' },
  { primary: 'rgb(15, 130, 68)', secondary: 'rgb(15  130  68 / 20%)' },
  { primary: 'rgb(60, 167, 88)', secondary: 'rgb(60  167  88 / 20%)' },
  { primary: 'rgb(59, 167, 87)', secondary: 'rgb(59  167  87 / 20%)' },
  { primary: 'rgb(69, 173, 90)', secondary: 'rgb(69  173  90 / 20%)' },
  { primary: 'rgb(88, 182, 95)', secondary: 'rgb(88  182  95 / 20%)' },
  {
    primary: `{
      "linearGradient": {
          "x1": 0,
          "x2": 1,
          "y1": 0,
          "y2": 0
      },
      "stops": [
          [
              0,
              "rgb(241, 111, 68)"
          ],
          [
              1,
              "rgb(191, 228, 123)"
          ]
      ]
  }`,
    secondary: `{
      "linearGradient": {
          "x1": 0,
          "x2": 1,
          "y1": 0,
          "y2": 0
      },
      "stops": [
          [
              0,
              "rgb(241  111  68 / 20%)"
          ],
          [
              1,
              "rgb(191  228  123 / 20%)"
          ]
      ]
  }`,
  },
  {
    primary: `{
      "linearGradient": {
          "x1": 0,
          "x2": 1,
          "y1": 0,
          "y2": 0
      },
      "stops": [
          [
              0,
              "rgb(250, 158, 91)"
          ],
          [
              1,
              "rgb(191, 228, 123)"
          ]
      ]
  }`,
    secondary: `{
      "linearGradient": {
          "x1": 0,
          "x2": 1,
          "y1": 0,
          "y2": 0
      },
      "stops": [
          [
              0,
              "rgb(250  158  91 / 20%)"
          ],
          [
              1,
              "rgb(191  228  123 / 20%)"
          ]
      ]
  }`,
  },
  {
    primary: `{
      "linearGradient": {
          "x1": 0,
          "x2": 1,
          "y1": 0,
          "y2": 0
      },
      "stops": [
          [
              0,
              "rgb(125, 199, 101)"
          ],
          [
              1,
              "rgb(191, 228, 123)"
          ]
      ]
  }`,
    secondary: `{
      "linearGradient": {
          "x1": 0,
          "x2": 1,
          "y1": 0,
          "y2": 0
      },
      "stops": [
          [
              0,
              "rgb(125  199  101 / 20%)"
          ],
          [
              1,
              "rgb(191  228  123 / 20%)"
          ]
      ]
  }`,
  },
  { primary: 'rgb(0, 104, 55)', secondary: 'rgb(0  104  55 / 20%)' },
  { primary: 'rgb(229, 80, 54)', secondary: 'rgb(229  80  54 / 20%)' },
];

export const activeNudges = [
  {
    userId: '270dcabb-ccf4-49eb-9e0e-686543208d4b',
    action: 'Technical Skills - Advanced',
    primary_driver: 'Technical Skills',
    actionCompleteDate: '2023-05-11T23:16:03.024Z',
    createdDate: '2023-02-11T18:30:00.000Z',
    doneDate: null,
    managerName: 'Leyla',
  },
  {
    userId: '270dcabb-ccf4-49eb-9e0e-686543208d4b',
    action: 'Meet with Product Teams',
    primary_driver: 'Time with Product team',
    actionCompleteDate: '2023-04-30T05:09:24.294Z',
    createdDate: '2023-01-30T18:30:00.000Z',
    doneDate: null,
    managerName: 'Leyla',
  },
  {
    userId: '270dcabb-ccf4-49eb-9e0e-686543208d4b',
    action: 'Practice Discovery skills',
    primary_driver: 'Discovery skills',
    actionCompleteDate: '2023-04-13T17:13:11.098Z',
    createdDate: '2023-01-13T18:30:00.000Z',
    doneDate: null,
    managerName: 'Leyla',
  },
  {
    userId: '270dcabb-ccf4-49eb-9e0e-686543208d4b',
    action: 'Focus on rigor with MEDPICC execution',
    primary_driver: 'MEDPICC score',
    actionCompleteDate: '2023-04-02T23:16:03.024Z',
    createdDate: '2023-01-02T18:30:00.000Z',
    doneDate: null,
    managerName: 'Leyla',
  },
  {
    userId: '270dcabb-ccf4-49eb-9e0e-686543208d4b',
    action: 'Follow up ratio with customer',
    primary_driver: 'Follow-up',
    actionCompleteDate: '2023-03-27T17:13:11.098Z',
    createdDate: '2022-12-27T18:30:00.000Z',
    doneDate: null,
    managerName: 'Leyla',
  },
  {
    userId: '270dcabb-ccf4-49eb-9e0e-686543208d4b',
    action: 'Focus on cadence with their Pipeline',
    primary_driver: 'Pipeline discipline',
    actionCompleteDate: '2023-03-14T23:16:03.024Z',
    createdDate: '2022-12-14T18:30:00.000Z',
    doneDate: null,
    managerName: 'Leyla',
  },
];
